import React from 'react';

import { Container, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import LoadingSpinner from '../LoadingSpinner';
import Breadcrumbs from './components/Breadcrumbs';
import Buttons from './components/Buttons';
import styles from './styles';

const useStyles = makeStyles(styles);

const Content = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {props.isFetching && <LinearProgress />}
      <Container maxWidth={false} disableGutters={true} className={classes.container}>
        {!props.isLoading ? (
          <React.Fragment>
            {props.breadcrumbs.length >= 0 && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
            {props.buttons.length >= 0 && <Buttons buttons={props.buttons} />}
            <div className={classes.innerContainer}>{props.children}</div>
          </React.Fragment>
        ) : (
          <LoadingSpinner />
        )}
      </Container>
    </React.Fragment>
  );
};

Content.defaultProps = {
  isFetching: false,
  breadcrumbs: [],
  buttons: []
};

Content.propTypes = {
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['primary', 'secondary', 'default', 'red']),
      variant: PropTypes.oneOf(['contained', 'outlined']),
      disabled: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
      size: PropTypes.oneOf(['small', 'medium', 'large'])
    })
  )
};

export default Content;
