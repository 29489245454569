import React from 'react';

import { Typography, Breadcrumbs as MuiBreadcrumbs, Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

class Breadcrumbs extends React.PureComponent {
  handleClick = (e, itemOnClick) => {
    e.preventDefault();
    itemOnClick();
  };

  render() {
    const { classes } = this.props;

    return (
      <MuiBreadcrumbs component={'div'} aria-label={'breadcrumb'} className={classes.container}>
        {this.props.breadcrumbs
          .filter((item) => !item.active)
          .map((item, index) => (
            <Link
              key={index}
              variant={'body1'}
              className={classes.breadcrumbPreviousTitle}
              href={item.href}
              onClick={(e) => {
                this.handleClick(e, item.onClick);
              }}
            >
              {item.title}
            </Link>
          ))}
        {this.props.breadcrumbs
          .filter((item) => item.active)
          .map((item, index) => (
            <Typography key={index} variant={'body1'} className={classes.breadcrumbActiveTitle}>
              {item.title}
            </Typography>
          ))}
      </MuiBreadcrumbs>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired
};

export default withStyles(styles)(Breadcrumbs);
