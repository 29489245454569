import React from 'react';

import { TablePagination } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

function PaginationFooter({ intl, ...props }) {
  return (
    <TablePagination
      size={'small'}
      padding={'none'}
      // labelRowsPerPage={'per page'}
      // labelDisplayedRows={({ from, to, count }) =>
      //   `${from}-${to} of ${count}`
      // }
      SelectProps={{ native: true }}
      {...props}
    />
  );
}

export default withStyles(styles)(PaginationFooter);
