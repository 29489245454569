import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
  DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../../buttons/Button';
import Title from '../../core/Title';
import styles from './styles';

const useStyles = makeStyles(styles);

const handleConfirm = (event, onConfirm) => {
  event.preventDefault();
  onConfirm();
};

const handleCancel = (event, onCancel) => {
  event.preventDefault();
  onCancel();
};

const ConfirmationDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.isOpened} maxWidth={props.maxWidth} fullWidth={true} keepMounted={true}>
      <div className={classes.titleContainer}>
        <Title title={props.title} />
      </div>
      <DialogContent className={classes.content}>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          label={props.cancelLabel}
          type={'button'}
          color={'secondary'}
          variant={'text'}
          size={'large'}
          onClick={(e) => handleCancel(e, props.onCancel)}
          disabled={props.disabled}
        />
        <Button
          label={props.confirmLabel}
          type={'button'}
          color={'primary'}
          variant={'text'}
          size={'large'}
          onClick={(e) => handleConfirm(e, props.onConfirm)}
          disabled={props.disabled}
        />
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
  isOpened: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default ConfirmationDialog;
