import { red } from '@material-ui/core/colors';

import TYPOGRAPHY from '../../core/ThemeProvider/typography';

const disabledColor = '#bbbbbb';
const disabledBackground = '#f7f7f7';

export default (theme) => ({
  root: {
    marginTop: '2px',
    marginBottom: '2px',
    '& .MuiInputBase-root': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.secondary.dark,
      fontSize: TYPOGRAPHY.h6.fontSize,
      fontWeight: 'bold',
      marginTop: '16px'
    },
    '& label.Mui-focused': {
      color: theme.palette.secondary.dark
    },
    '& label.Mui-error': {
      color: red[400]
    },
    '& label.Mui-disabled': {
      color: disabledColor
    },
    '& select.MuiInputBase-input': {
      fontSize: TYPOGRAPHY.subtitle1.fontSize,
      marginTop: '12px',
      paddingBottom: '12px'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: '1px',
        borderColor: theme.palette.secondary.main,
        transitionProperty: 'border-color, border-width',
        transitionDuration: '100ms',
        transitionTimingFunction: 'ease-in'
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary.main,
        borderWidth: '1px'
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary.dark,
        borderWidth: '2px'
      },
      '&.Mui-disabled': {
        backgroundColor: disabledBackground
      },
      '&.Mui-disabled select': {
        backgroundColor: disabledBackground,
        color: disabledColor
      },
      '&.Mui-disabled fieldset': {
        backgroundColor: 'transparent',
        borderColor: disabledColor,
        borderWidth: '1px'
      },
      '& legend': {
        maxWidth: 0
      }
    }
  }
});
