import colors from '../../utils/colors';

export default (theme) => ({
  container: {
    flex: 1,
    padding: theme.spacing(2),
    border: `1px solid ${colors.border}`,
    borderRadius: '6px'
  },
  clickableContainer: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dbd9dd',
      border: `1px solid ${theme.palette.secondary.dark}`
    }
  },
  containerWithoutPadding: {
    flex: 1,
    border: `1px solid ${colors.border}`,
    borderRadius: '6px'
  }
});
