export default (theme) => ({
  container: {
    flex: 1,
    padding: theme.spacing(2)
  },
  innerContainer: {
    flex: 1,
    marginTop: theme.spacing()
  }
});
