import React from 'react';

import {
  FormHelperText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Chips from './components/Chips';
import styles from './styles';

const useStyles = makeStyles(styles);

const MultipleSelect = ({
  name,
  label,
  options,
  values,
  onChange,
  disabled,
  required,
  helperText,
  ...props
}) => {
  const classes = useStyles();

  const selectedNames = options
    .filter((item) => values.includes(item.value))
    .map((item) => item.name);

  return (
    <FormControl
      label={label}
      variant={'outlined'}
      margin={'normal'}
      fullWidth={true}
      required={required}
      className={classes.root}
      {...props}
    >
      <InputLabel shrink={true} required={required}>
        {label}
      </InputLabel>
      <Select
        name={name}
        label={label}
        fullWidth={true}
        multiple={true}
        variant={'outlined'}
        value={values}
        onChange={onChange}
        disabled={disabled}
        renderValue={() => <Chips names={selectedNames} />}
      >
        {options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox checked={values.includes(item.value)} />
            <ListItemText secondary={item.name} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

MultipleSelect.defaultProps = {
  disabled: false,
  required: false,
  helperText: ''
};

MultipleSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string
};

export default MultipleSelect;
