import { green, yellow, red } from '@material-ui/core/colors';

export default (theme) => ({
  green: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    backgroundColor: green[700]
  },
  yellow: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    backgroundColor: yellow[700]
  },
  red: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    backgroundColor: red[700]
  }
});
