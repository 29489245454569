import { red } from '@material-ui/core/colors';

export default (theme) => ({
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  removeButton: {
    color: theme.palette.common.white,
    backgroundColor: red[700],
    '&:hover': {
      backgroundColor: red[900]
    }
  },
  removeButtonOutlined: {
    color: red[700],
    borderColor: red[700],
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      borderColor: red[900],
      backgroundColor: red[100]
    },
    '&:disabled': {
      color: red[200],
      borderColor: red[50],
      backgroundColor: red[50]
    }
  }
});
