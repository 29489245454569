export default (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    '&$checked': {
      color: theme.palette.secondary.main
    },
    '& .MuiFormControlLabel-root': {
      color: theme.palette.secondary.main
    }
  },
  checked: {},
  label: {
    fontWeight: 600,
    color: theme.palette.secondary.dark
  }
});
