import React from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const Input = ({ name, label, type, value, onChange, inputRef, error, helperText, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      inputRef={inputRef}
      name={name}
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      variant={'outlined'}
      margin={'normal'}
      fullWidth={true}
      className={classes.root}
      {...props}
    />
  );
};

Input.defaultProps = {
  inputRef: React.createRef(),
  error: false,
  helperText: ''
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default Input;
