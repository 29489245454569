import React from 'react';

import PropTypes from 'prop-types';

// APP IMPORTS
import Button from '../../../../buttons/Button';

class Buttons extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        {this.props.buttons.map((item, index) => (
          <Button
            key={index}
            label={item.label}
            type={'button'}
            color={item.color}
            variant={item.variant}
            size={item.size}
            onClick={item.onClick}
            disabled={item.disabled}
          />
        ))}
      </React.Fragment>
    );
  }
}

Buttons.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.oneOf(['primary', 'secondary', 'default', 'red']),
      variant: PropTypes.oneOf(['contained', 'outlined']),
      disabled: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
      size: PropTypes.oneOf(['small', 'medium', 'large'])
    })
  ).isRequired
};

export default Buttons;
