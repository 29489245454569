import React from 'react';

import { Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from 'react-feather';

import styles from './styles';

class MobileTable extends React.PureComponent {
  render() {
    const { classes } = this.props;
    const maxPageNumber = Math.ceil(this.props.totalCount / this.props.rowsPerPage);

    return (
      <Grid container spacing={2} justifyContent={'center'}>
        {this.props.data.length === 0 && (
          <Grid item xs={12}>
            <Typography variant={'body1'} color={'textSecondary'}>
              {this.props.noDataMessage}
            </Typography>
          </Grid>
        )}
        {this.props.data.map((component, index) => (
          <Grid item xs={12} key={index}>
            {component}
          </Grid>
        ))}
        {this.props.usePagination && (
          <Grid item xs={12}>
            <div className={classes.paginationContainer}>
              <IconButton
                color={'primary'}
                size={'medium'}
                disabled={this.props.page === 0}
                onClick={() => {
                  this.props.onChange(
                    this.props.page - 1,
                    this.props.rowsPerPage,
                    this.props.sortBy,
                    this.props.sortDirection
                  );
                }}
              >
                <ChevronLeft size={20} />
              </IconButton>
              <IconButton
                color={'primary'}
                size={'medium'}
                className={classes.paginationRightButton}
                disabled={maxPageNumber === 0 || this.props.page + 1 === maxPageNumber}
                onClick={() => {
                  this.props.onChange(
                    this.props.page + 1,
                    this.props.rowsPerPage,
                    this.props.sortBy,
                    this.props.sortDirection
                  );
                }}
              >
                <ChevronRight size={20} />
              </IconButton>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

MobileTable.defaultProps = {
  totalCount: 0,
  page: 0,
  rowsPerPage: 20
};

MobileTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
  noDataMessage: PropTypes.string.isRequired,

  // handle changes
  onChange: PropTypes.func.isRequired,

  // sorting (ignore these)
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,

  // pagination
  usePagination: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number
};

export default withStyles(styles)(MobileTable);
