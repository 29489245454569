import React from 'react';

import { Typography, List, ListItem, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Paper from '../Paper';
import styles from './styles';

const useStyles = makeStyles(styles);

const PaperListInformation = (props) => {
  const classes = useStyles();

  return (
    <Paper padding={false}>
      <List disablePadding={true}>
        {props.items.map((item, index) => {
          const clickable = !!item.onClick;

          return (
            <React.Fragment key={index}>
              <ListItem
                button={clickable}
                onClick={() => {
                  if (clickable) {
                    item.onClick();
                  }
                }}
              >
                {item.title !== undefined && item.value !== undefined && (
                  <div>
                    <Typography variant={'body1'} color={'secondary'} className={classes.title}>
                      {item.title}
                    </Typography>
                    <Typography variant={'h6'} color={'primary'} className={classes.value}>
                      {item.value}
                    </Typography>
                  </div>
                )}
                {item.component && item.component}
              </ListItem>
              {index + 1 !== props.items.length && (
                <Divider light={true} className={classes.dividerColor} />
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

PaperListInformation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        value: PropTypes.any.isRequired,
        onClick: PropTypes.func
      }),
      PropTypes.shape({
        component: PropTypes.any.isRequired,
        onClick: PropTypes.func
      })
    ])
  ).isRequired
};

export default PaperListInformation;
