import React from 'react';

import { Paper as MuiPaper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Title from '../Title';
import styles from './styles';

const useStyles = makeStyles(styles);

const Paper = (props) => {
  const classes = useStyles();

  let className = classes.container;

  if (!props.padding) className = classes.containerWithoutPadding;

  if (props.clickable) {
    className = `${className} ${classes.clickableContainer}`;
  }

  return (
    <MuiPaper className={className} elevation={0} onClick={props.onClick}>
      {props.title !== '' && <Title title={props.title} />}
      <React.Fragment>{props.children}</React.Fragment>
    </MuiPaper>
  );
};

Paper.defaultProps = {
  title: '',
  padding: true,
  clickable: false,
  onClick: () => {}
};

Paper.propTypes = {
  title: PropTypes.string,
  padding: PropTypes.bool,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

export default Paper;
