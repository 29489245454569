import React from 'react';

import { Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../../buttons/Button';
import Title from '../../core/Title';
import styles from './styles';

const useStyles = makeStyles(styles);

const handleSubmit = (event, onConfirm) => {
  event.preventDefault();
  onConfirm();
};

const handleCancel = (event, onCancel) => {
  event.preventDefault();
  onCancel();
};

const FormDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.isOpened} maxWidth={props.maxWidth} fullWidth={true} keepMounted={true}>
      <form autoComplete={props.autoComplete} onSubmit={(e) => handleSubmit(e, props.onConfirm)}>
        <div className={classes.titleContainer}>
          <Title title={props.title} />
        </div>
        <DialogContent className={classes.content}>{props.children}</DialogContent>
        <Divider />
        <DialogActions>
          <Button
            label={props.cancelLabel}
            type={'button'}
            color={'secondary'}
            variant={'text'}
            size={'large'}
            onClick={(e) => handleCancel(e, props.onCancel)}
            disabled={props.onCancelDisabled}
          />
          <Button
            label={props.confirmLabel}
            type={'submit'}
            color={'primary'}
            variant={'text'}
            size={'large'}
            onClick={() => {}}
            disabled={props.onConfirmDisabled}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
};

FormDialog.defaultProps = {
  autoComplete: 'on'
};

FormDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancelDisabled: PropTypes.bool.isRequired,
  onConfirmDisabled: PropTypes.bool.isRequired,
  autoComplete: PropTypes.oneOf(['on', 'off'])
};

export default FormDialog;
