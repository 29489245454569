import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ArrowDown } from 'react-feather';

import Paper from '../../../Paper';
import Title from '../../../Title';
import PaginationFooter from './components/PaginationFooter';
import styles from './styles';

// Icon component
const SortIcon = React.forwardRef(function SortIcon(props, ref) {
  return <ArrowDown size={14} ref={ref} {...props} />;
});

class WebTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      rowsPerPage: 20,
      sortBy: 'id',
      sortDirection: 'asc'
    };
  }

  componentDidMount() {
    this.setState({
      page: this.props.page,
      rowsPerPage: this.props.rowsPerPage,
      sortBy: this.props.sortBy,
      sortDirection: this.props.sortDirection
    });
  }

  handleChangeSortBy = (name) => {
    this.setState(
      (state) => {
        if (state.sortBy === name) {
          return {
            sortBy: name,
            sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc'
          };
        }

        return {
          sortBy: name,
          sortDirection: 'asc'
        };
      },
      () => {
        this.props.onChange(
          this.state.page,
          this.state.rowsPerPage,
          this.state.sortBy,
          this.state.sortDirection
        );
      }
    );
  };

  handlePageChange = (page) => {
    this.setState({ page: page }, () => {
      this.props.onChange(
        this.state.page,
        this.state.rowsPerPage,
        this.state.sortBy,
        this.state.sortDirection
      );
    });
  };

  handleRowsPerPageChange = (rows) => {
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(rows, 10)
      },
      () => {
        this.props.onChange(
          this.state.page,
          this.state.rowsPerPage,
          this.state.sortBy,
          this.state.sortDirection
        );
      }
    );
  };

  render() {
    const { classes, title, headers, data, noDataMessage } = this.props;

    return (
      <Paper padding={false}>
        <div className={`${classes.container} ${title !== '' ? classes.containerWithTitle : ''}`}>
          <Table size={'small'}>
            {title !== '' && (
              <TableHead>
                <TableRow className={classes.titleRow}>
                  <TableCell className={classes.titleCell}>
                    <Title title={this.props.title} translateTitle={false} />
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableHead>
              <TableRow className={classes.headerRow}>
                {headers.map((header) => (
                  <React.Fragment key={header.title}>
                    {this.props.useSorting && header.sort ? (
                      <TableCell
                        align={header.align}
                        className={classes.headerCell}
                        sortDirection={
                          header.sort && header.sortKey === this.state.sortBy
                            ? this.state.sortDirection
                            : false
                        }
                      >
                        <TableSortLabel
                          active={header.sortKey === this.state.sortBy}
                          direction={this.state.sortDirection}
                          onClick={() => this.handleChangeSortBy(header.sortKey)}
                          className={classes.headerCellSort}
                          IconComponent={SortIcon}
                        >
                          {header.title}
                        </TableSortLabel>
                      </TableCell>
                    ) : (
                      <TableCell align={header.align} className={classes.headerCell}>
                        {header.title}
                      </TableCell>
                    )}
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow className={classes.bodyRow}>
                  <TableCell className={classes.bodyCell} colSpan={headers.length}>
                    {noDataMessage}
                  </TableCell>
                </TableRow>
              ) : (
                <React.Fragment>
                  {data.map((row, index) => (
                    <TableRow
                      key={index}
                      className={`${classes.bodyRow} ${index % 2 !== 0 ? classes.bodyRowEven : ''}`}
                    >
                      {row.map((cell, cellIndex) => (
                        <TableCell
                          key={cellIndex}
                          className={classes.bodyCell}
                          align={headers[cellIndex].align}
                        >
                          {cell}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
            {this.props.usePagination && (
              <TableFooter>
                <TableRow className={classes.paginationRow}>
                  <PaginationFooter
                    count={this.props.totalCount}
                    rowsPerPageOptions={this.props.rowsPerPageOptions}
                    page={this.state.page}
                    rowsPerPage={this.state.rowsPerPage}
                    onPageChange={(_, page) => {
                      this.handlePageChange(page);
                    }}
                    onRowsPerPageChange={(event) => {
                      this.handleRowsPerPageChange(event.target.value);
                    }}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </div>
      </Paper>
    );
  }
}

WebTable.propTypes = {
  title: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
      sort: PropTypes.bool,
      sortKey: PropTypes.string
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  noDataMessage: PropTypes.string.isRequired,

  // handle changes
  onChange: PropTypes.func.isRequired,

  // sort
  useSorting: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,

  // pagination
  usePagination: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default withStyles(styles)(WebTable);
