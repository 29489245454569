import colors from '../../utils/colors';

export default (theme) => ({
  title: {
    textTransform: 'lowercase'
  },
  value: {
    fontWeight: 'bold',
    marginTop: theme.spacing(-0.5)
  },
  dividerColor: {
    backgroundColor: colors.border,
    height: '0.5px'
  }
});
