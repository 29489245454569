import React from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const Title = (props) => {
  const classes = useStyles();
  return (
    <Typography variant={'h6'} className={classes.title}>
      {props.title}
    </Typography>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired
};

export default Title;
