import React from 'react';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const Select = ({
  showEmpty,
  emptyLabel,
  name,
  label,
  type,
  value,
  options,
  onChange,
  inputRef,
  error,
  helperText,
  multiple,
  ...props
}) => {
  const classes = useStyles();

  return (
    <TextField
      inputRef={inputRef}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      helperText={helperText}
      InputLabelProps={{ shrink: true }}
      variant={'outlined'}
      margin={'normal'}
      required={false}
      fullWidth={true}
      select={true}
      SelectProps={{ native: true }}
      className={classes.root}
      {...props}
    >
      {showEmpty && !emptyLabel && <option aria-label={'None'} value={''} />}
      {showEmpty && emptyLabel && (
        <option aria-label={'None'} value={''}>
          {emptyLabel}
        </option>
      )}
      {options.map((item) => (
        <option key={item.key} value={item.value}>
          {item.name}
        </option>
      ))}
    </TextField>
  );
};

Select.defaultProps = {
  showEmpty: true,
  emptyLabel: '',
  inputRef: React.createRef(),
  error: false,
  helperText: ''
};

Select.propTypes = {
  showEmpty: PropTypes.bool,
  emptyLabel: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.array])
    .isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string
};

export default Select;
