export default (theme) => ({
  title: {
    color: theme.palette.secondary.dark,
    textTransform: 'uppercase',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'bold'
  }
});
