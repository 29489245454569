export default (theme) => ({
  innerContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  avatarContainer: {
    marginRight: theme.spacing(2)
  },
  avatar: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary.light
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textInformation: {
    fontWeight: 'bold',
    color: theme.palette.common.black
  },
  textTitle: {
    fontWeight: '500',
    color: theme.palette.secondary.dark
  }
});
