import React from 'react';

import { Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';

import MobileTable from './components/MobileTable';
import WebTable from './components/WebTable';

class Table extends React.PureComponent {
  renderWeb = () => {
    return (
      <WebTable
        title={this.props.title}
        headers={this.props.webHeaders}
        data={this.props.webData}
        noDataMessage={this.props.noDataMessage}
        onChange={this.props.onChange}
        // sorting
        useSorting={this.props.useSorting}
        sortBy={this.props.sortBy}
        sortDirection={this.props.sortDirection}
        // pagination
        usePagination={this.props.usePagination}
        totalCount={this.props.totalCount}
        page={this.props.page}
        rowsPerPage={this.props.rowsPerPage}
        rowsPerPageOptions={this.props.rowsPerPageOptions}
      />
    );
  };

  render() {
    if (this.props.renderMobile) {
      return (
        <React.Fragment>
          <Hidden mdUp={true}>
            <MobileTable
              data={this.props.mobileData}
              noDataMessage={this.props.noDataMessage}
              onChange={this.props.onChange}
              // sorting
              sortBy={this.props.sortBy}
              sortDirection={this.props.sortDirection}
              // pagination
              usePagination={this.props.usePagination}
              totalCount={this.props.totalCount}
              page={this.props.page}
              rowsPerPage={this.props.rowsPerPage}
            />
          </Hidden>
          <Hidden smDown={true}>{this.renderWeb()}</Hidden>
        </React.Fragment>
      );
    }

    return this.renderWeb();
  }
}

Table.defaultProps = {
  title: '',
  webHeaders: [],
  webData: [],
  mobileData: [],
  renderMobile: false,

  // handle changes
  onChange: () => {},

  // sorting
  useSorting: false,
  sortBy: 'id',
  sortDirection: 'asc',

  // pagination
  usePagination: false,
  totalCount: 0,
  page: 0,
  rowsPerPage: 20,
  rowsPerPageOptions: [20, 50, 100]
};

Table.propTypes = {
  title: PropTypes.string,
  webHeaders: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      align: PropTypes.oneOf(['inherit', 'left', 'center', 'right', 'justify']),
      sort: PropTypes.bool,
      sortKey: PropTypes.string
    })
  ),
  webData: PropTypes.array,
  mobileData: PropTypes.array,
  noDataMessage: PropTypes.string.isRequired,
  renderMobile: PropTypes.bool,

  // handle changes
  onChange: PropTypes.func,

  // sorting
  useSorting: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),

  // pagination
  usePagination: PropTypes.bool,
  totalCount: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};

export default Table;
