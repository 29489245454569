import React from 'react';

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  Checkbox as MuiCheckbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const Checkbox = ({
  name,
  label,
  value,
  onChange,
  inputRef,
  error,
  helperText,
  disabled,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl error={error} className={classes.root}>
      <FormControlLabel
        label={
          <Typography variant={'body1'} className={classes.label}>
            {label}
          </Typography>
        }
        control={
          <MuiCheckbox
            inputRef={inputRef}
            name={name}
            checked={value}
            onChange={onChange}
            disabled={disabled}
            {...props}
          />
        }
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

Checkbox.defaultProps = {
  inputRef: React.createRef(),
  error: false,
  helperText: '',
  disabled: false
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  inputRef: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
};

export default Checkbox;
