import React from 'react';

import { Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Paper from '../Paper';
import styles from './styles';

const useStyles = makeStyles(styles);

const PaperInformation = ({ icon: Icon, ...props }) => {
  const classes = useStyles();

  return (
    <Paper clickable={props.clickable} onClick={props.onClick}>
      <div className={classes.innerContainer}>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.avatar}>
            <Icon size={20} />
          </Avatar>
        </div>
        <div className={classes.textContainer}>
          <Typography variant={'h4'} className={classes.textInformation}>
            {props.information}
          </Typography>
          <Typography variant={'body1'} className={classes.textTitle}>
            {props.title}
          </Typography>
        </div>
      </div>
    </Paper>
  );
};

PaperInformation.defaultProps = {
  clickable: false,
  onClick: () => {}
};

PaperInformation.propTypes = {
  title: PropTypes.string.isRequired,
  information: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  icon: PropTypes.object.isRequired,
  clickable: PropTypes.bool,
  onClick: PropTypes.func
};

export default PaperInformation;
