export default (theme) => ({
  container: {
    marginBottom: theme.spacing(1)
  },
  breadcrumbPreviousTitle: {
    color: theme.palette.primary
  },
  breadcrumbActiveTitle: {
    color: theme.palette.secondary.dark
  }
});
