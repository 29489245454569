import React from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const StateBadge = (props) => {
  const classes = useStyles();

  const classesMapping = {
    green: classes.green,
    red: classes.red,
    yellow: classes.yellow
  };

  return <Chip size={props.size} label={props.label} className={classesMapping[props.color]} />;
};

StateBadge.defaultProps = {
  size: 'small'
};

StateBadge.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['green', 'red', 'yellow']).isRequired,
  size: PropTypes.oneOf(['small', 'medium'])
};

export default StateBadge;
