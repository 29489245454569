export default (theme) => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end'
  },
  paginationRightButton: {
    marginLeft: theme.spacing(2)
  }
});
