import React from 'react';

import { Dialog, DialogContent, DialogActions, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Button from '../../buttons/Button';
import Title from '../../core/Title';
import styles from './styles';

const useStyles = makeStyles(styles);

const handleCancel = (event, onCancel) => {
  event.preventDefault();
  onCancel();
};

const InformationDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.isOpened} maxWidth={props.maxWidth} fullWidth={true} keepMounted={true}>
      <div className={classes.titleContainer}>
        <Title title={props.title} />
      </div>
      <DialogContent className={classes.content}>{props.children}</DialogContent>
      <Divider />
      <DialogActions>
        <Button
          label={props.closeLabel}
          type={'button'}
          color={'secondary'}
          variant={'text'}
          size={'large'}
          onClick={(e) => handleCancel(e, props.onCancel)}
          disabled={props.onCancelDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};

InformationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  isOpened: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string.isRequired,
  closeLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCancelDisabled: PropTypes.bool.isRequired
};

export default InformationDialog;
