import React from 'react';

import { Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

class Chips extends React.PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.props.names.map((name) => (
          <Chip key={name} label={name} className={classes.chip} />
        ))}
      </div>
    );
  }
}

Chips.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default withStyles(styles)(Chips);
