import React from 'react';

import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './styles';

const useStyles = makeStyles(styles);

const Button = (props) => {
  const classes = useStyles();

  let buttonClass = classes.button;
  if (props.color === 'red') {
    buttonClass = `${buttonClass} ${classes.removeButton}`;

    if (props.variant === 'outlined') {
      buttonClass = `${buttonClass} ${classes.removeButtonOutlined}`;
    }
  }

  return (
    <MuiButton
      type={props.type}
      color={props.color === 'red' ? 'default' : props.color}
      variant={props.variant}
      className={buttonClass}
      disabled={props.disabled}
      onClick={props.onClick}
      disableElevation={true}
      startIcon={props.icon}
      size={props.size}
    >
      {props.label}
    </MuiButton>
  );
};

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  size: 'medium',
  icon: null,
  onClick: null
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']).isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'default', 'red']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

export default Button;
