import React from 'react';

import { createTheme, responsiveFontSizes } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import shadows from './shadows';
import typography from './typography';
import zIndex from './zIndex';

class ThemeProvider extends React.PureComponent {
  render() {
    const { palette } = this.props;

    const theme = createTheme({
      palette: palette,
      typography: typography,
      zIndex: zIndex,
      shadows: shadows
    });

    return (
      <MuiThemeProvider theme={responsiveFontSizes(theme)}>{this.props.children}</MuiThemeProvider>
    );
  }
}

ThemeProvider.propTypes = {
  palette: PropTypes.object.isRequired
};

export default ThemeProvider;
