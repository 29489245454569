import colors from '../../../../utils/colors';
import typography from '../../../ThemeProvider/typography';

const radius = '6px';

export default (theme) => ({
  container: {
    borderRadius: radius,
    paddingTop: theme.spacing(0.5),
    backgroundColor: colors.tableBackgroundColor,
    overflow: 'hidden'
  },
  containerWithTitle: {
    backgroundColor: '#fff'
  },

  // title
  titleRow: {
    backgroundColor: '#fff',
    borderRadius: radius,
    borderBottom: `1px solid ${colors.border}`
  },
  titleCell: {
    borderBottom: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5)
  },

  // header
  headerRow: {
    backgroundColor: colors.tableBackgroundColor,
    borderBottom: `1px solid ${colors.border}`,
    borderRadius: radius
  },
  headerCell: {
    color: theme.palette.common.black,
    borderBottom: 0,
    fontWeight: 'bold',
    fontSize: typography.subtitle1.fontSize,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  headerCellSort: {
    color: `${theme.palette.common.black} !important`
  },

  // body
  bodyRow: {
    backgroundColor: '#fff'
  },
  bodyRowEven: {
    backgroundColor: colors.tableRowBackgroundColor
  },
  bodyCell: {
    color: theme.palette.secondary.dark,
    borderBottom: 0,
    fontSize: typography.subtitle1.fontSize
  },

  // pagination
  paginationRow: {
    padding: 0,
    backgroundColor: colors.tableBackgroundColor
  }
});
