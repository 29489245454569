import colors from '../../../../../../utils/colors';

export default (theme) => ({
  root: {
    backgroundColor: colors.tableBackgroundColor,
    borderBottom: `1px solid ${colors.tableBackgroundColor}`,
    '& .MuiTablePagination-caption': {
      color: theme.palette.common.black,
      fontWeight: 'bold'
    },
    '& .MuiTablePagination-select': {
      color: theme.palette.common.black
    }
  }
});
